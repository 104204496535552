import './footer.scss';
import { useNavigate } from 'react-router-dom';
import emailSvg from '../img/footer_email.svg';
import bfSvg from '../img/footer_bf.svg';

const Footer = () => {
    const navigate = useNavigate();

    const handleClick = (route) => {
      navigate(route);
    };
    return (
        <div className="row" id="footer">
            <div className="col-sm-12">
                <div className="feedback">
                    <a href="mailto:brand@i-tob.ru">brand@i-tob.ru</a>
                    <p>Вопросы, предложения,<br/>дополнительная информация.</p>
                </div>
                <img src={emailSvg} alt="#" className="email"/>                
                <button onClick={() => handleClick('/feedback')}>обратная связь</button>
                <img src={bfSvg} alt="#" className="bf"/>
                <ul>
                    <li>© 2024 Сайт «Jadé»</li>
                    <li><a href="https://www.jade.deluxetest.ru/Правила_Конкурса_Jade.pdf">Правила использования сайта</a></li>
                    <li><a href="https://www.jade.deluxetest.ru/Политика_обработки_персональных_данных_Jade.pdf">Политика в отношении обработки<br/>персональных данных</a></li>
                    <li><a href="https://www.jade.deluxetest.ru/Пользовательское-соглашение_-Jade.pdf">Положение о конфиденциальности</a></li>
                    <li><a href="https://drive.google.com/file/d/1pvEvf6az5VYSScmuU4PvlxgS9K3tO1LP/view?usp=drive_link">Риски, связанные со здоровьем</a></li>
                    <li><a href="https://www.jade.deluxetest.ru/Ответы_на_часто_задаваемые_вопросы_Jade.pdf">Вопросы и ответы</a></li>
                </ul>
            </div>
        </div> 
    )
}

export default Footer;